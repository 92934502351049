<template>
	<v-sheet color="transparent" class="pa-4">
		<SearchAndFilter 
		:items="items" 
		@search="(data) => search = data" 
		@filtered="filterItems"
		@cleared="filtered = false"
		:fieldOptions="['beds', 'type', 'universityHospital']"
		></SearchAndFilter>
		<v-data-table :headers="headers" :items="items" :search="search">
			<template v-slot:item.button="{ item }">
				<ListButtons :id="item.id" collection="organisations"></ListButtons>
			</template>			
		</v-data-table>
		<p class="text-right">
			<v-btn color="primary" to="/admin/organisations/new">{{mwtr.NewOrganisationNavItem}}</v-btn>
		</p>
	</v-sheet>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import ListButtons from "@/components/admin/lists/ListButtons"
	import SearchAndFilter from "@/components/admin/panels/SearchAndFilter"

	export default {
		name: "Organisations", 
		props: {
			
		},
		data: () => {
			return {
				search: "", 
				dialog: false,
				filtered: false,
				filteredItems: []

			}
		},
		components: {
			ListButtons,
			SearchAndFilter
		},
		computed: {
			...Vuex.mapState({
				organisations: state => state.organisations
			}), 
			headers(){
				return [
					{value: "name", text: this.mwtr.OrganisationName},
					{value: "type", text: this.mwtr.OrganisationType},
					{value: "owner", text: this.mwtr.OrganisationOwner},
					{value: "address.state", text: this.mwtr.OrganisationState},
					{value: "button", text: " "},
				]
			}, 
			items(){
				var items = Object.values(this.organisations);
				var filteredItems = this.filteredItems;
				if( !this.filtered ){
					return items;
				}
				else{
					return items.filter( i => filteredItems.find( f => f.id == i.id) );
				}
			}
		},
		methods: {
			filterItems(filter){
				this.filtered = true;
				this.filteredItems = filter;
			}
		},
		created(){
			this.$store.dispatch("organisations/fetchAll")
      }

	}
// </script>"