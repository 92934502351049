<template>
	<div>
		<v-text-field v-model="search" :label="mwtr.Search" append-icon="mdi-filter" @click:append="dialog = true"></v-text-field>
		<v-dialog :width="mwstylesettings.MediumDialogWidth" v-model="dialog">
			<v-card>
				<v-card-title></v-card-title>
				<v-card-text>
					<v-row dense v-for="(filter, i) in filters" :key="i">
						<v-col>
							<v-select :items="fieldOptions" v-model="filter.field"></v-select>
						</v-col>
						<v-col>
							<v-select :items="operators" v-model="filter.operator"></v-select>
						</v-col>
						<v-col>
							<v-text-field v-model="filter.value"></v-text-field>
						</v-col>
					</v-row>
					<p class="text-right">					
						<v-chip color="primary" text>
							{{filteredItems.length || 0}}
						</v-chip>
					</p>
				</v-card-text>
				<v-card-actions>
					<v-btn dark color="primary" text @click="addFilter">{{mwtr.AddFilter}}</v-btn>
					<v-spacer></v-spacer>
					<v-btn dark color="red" @click="clearFilters">{{mwtr.ClearFilters}}</v-btn>
					<v-btn dark color="primary" @click="saveFilters">{{mwtr.SubmitButton}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import Vuex from "vuex";
	// import - from "@/components/"
	export default {
		name: "SearchAndFilter", 
		props: {
			items: {type: Array},
			fieldOptions: {type: Array }
		},
		data: () => {
			return {
				dialog: false,
				search: "", 
				filters: [],
				operators: ["==", "!=", "<", ">"]
			}
		},
		computed: {
			filteredItems(){
				var items = this.items;
				this.filters.forEach( f => {
					if( f.field && f.operator && !(typeof f.value == 'undefined') ){
						items = items.filter( i => {
							if( f.value == "false" || f.operator == "true" ){
								return this.numTest(i, f );
							}
							if( f.operator == ">" || f.operator == "<" ){
								return this.numTest(i, f );
							}
							else{
								return this.stringTest(i, f)
							}
						} ) 
					}
				})
				return items;
			}
		},
		watch: {
			search(){
				this.$emit("search", this.search )
			},
		},
		methods: {
			stringTest(item, filter){
				var value1 = item[filter.field];
				var value2 = filter.value; 
				var	test = `"${value1}" ${filter.operator} "${value2}"`;
				return eval(test);
			},
			numTest(item, filter){
				var value1 = item[filter.field];
				var value2 = filter.value; 
				var	test = `${value1} ${filter.operator} ${value2}`;
				return eval(test);
			}, 
			saveFilters(){
				this.$emit("filtered", this.filteredItems );
				this.dialog = false;
			},
			clearFilters(){
				this.filters = [];
				this.$emit("cleared")
			},
			addFilter(){
				this.filters.push({
					field: "", 
					operator: "", 
					value: 0
				})
			}
		},		
		// components: {

		// },
		// computed: {
			// ...Vuex.mapState({})
		// },
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"